import React, { Fragment } from "react";
import clsx from "clsx";
import { Tab } from "@headlessui/react";
import Section from "../section";

const ImageAccordionSection = ({ title, description, tabs }) => {
    return (
        <Section>
            <div className="max-w-2xl text-center md:mx-auto md:text-center xl:max-w-none">
                <h1 className="font-display font-bold text-3xl md:text-4xl tracking-tight">
                    {title}
                </h1>
                <p className="mt-2 text-xl text-gray-700 tracking-tight">
                    {description}
                </p>
            </div>
            <div className="mt-10 grid grid-cols-1 items-center gap-y-2 sm:gap-y-6 md:mt-12 lg:grid-cols-12 lg:pt-0">
                <Tab.Group vertical>
                    <div className="-mx-4 flex sm:pl-0 lg:pl-6 xl:pl-0 overflow-x-auto pb-4 sm:mx-0 sm:overflow-visible sm:pb-0 lg:col-span-5">
                        <Tab.List
                            as="div"
                            className="relative flex gap-x-4 whitespace-nowrap px-8 sm:mx-auto sm:px-0 lg:mx-0 lg:block lg:gap-x-0 lg:gap-y-1 lg:whitespace-normal"
                        >
                            {tabs.map((tab) => (
                                <Tab key={tab.name} as={Fragment}>
                                    {({ selected }) => (
                                        <div
                                            className={clsx(
                                                selected && "border-red-500 bg-red-50",
                                                !selected && "border-transparent",
                                                "border group cursor-pointer flex flex-col relative rounded-full py-1 px-4 lg:rounded-r-none lg:rounded-l-xl lg:p-6 lg:ring-1 lg:ring-inset lg:ring-white/10"
                                            )}
                                        >
                                            <h3 className="font-display font-semibold text-lg md:text-xl [&:not(:focus-visible)]:focus:outline-none">
                                                {tab.name}
                                            </h3>
                                            <p className="mt-2 hidden text-base font-light text-left lg:block">
                                                {tab.description}
                                            </p>
                                        </div>
                                    )}
                                </Tab>
                            ))}
                        </Tab.List>
                    </div>
                    <div className="lg:col-span-7">
                        <Tab.Panels as={Fragment}>
                            {tabs.map((tab) => (
                                <Tab.Panel key={tab.name}>
                                    {({ selected }) => (
                                        <div className="transform lg:-translate-x-[1px]">
                                            <div className="relative mt-2 sm:px-6 lg:hidden">
                                                <p className="relative mx-auto max-w-2xl text-base sm:text-center">
                                                    {tab.description}
                                                </p>
                                            </div>
                                            <div className="mt-10 w-full overflow-hidden rounded-xl bg-slate-50 shadow-xl shadow-gray-900/20 sm:w-auto lg:mt-0 lg:w-[67.8125rem]">
                                                {tab.Image}
                                            </div>
                                        </div>
                                    )}
                                </Tab.Panel>
                            ))}
                        </Tab.Panels>
                    </div>
                </Tab.Group>
            </div>
        </Section>
    );
};

export default ImageAccordionSection;
