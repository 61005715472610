import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { StaticImage } from "gatsby-plugin-image";

const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    }
};

// const logos = [
//     {name: "AWS_EC2_Logo", fileType: "png"},
//     {name: "AWS_EKS_Logo", fileType: "png"},
//     {name: "AWS_Logo", fileType: "png"},
// ];

class LogoCarousel extends React.Component {
    render () {
        return (
            <Carousel 
                swipeable={true}
                draggable={false}
                responsive={responsive}
                // centerMode={true} //Shows partials of next and previus images
                ssr={true} // means to render carousel on server-side.
                infinite={true}
                autoPlay={true}
                onMouseOver={this.autoPlay=false}
                keyBoardControl={true}
                transitionDuration={300}
                containerClass="carousel-container"
                removeArrowOnDeviceType={["tablet", "mobile"]}
                deviceType={this.props.deviceType}
                shouldResetAutoplay={true}
            >
                {/* <div className='flex justify-center'>
                    <StaticImage
                    src="../assets/partnership-logos/IBM_logo.svg"
                    placeholder="blurred"
                    loading="lazy"
                    height={90}
                    alt="IBM logo"
                    />
                </div>
                <div className='flex justify-center'>
                    <StaticImage
                    src="../assets/partnership-logos/Red_Hat_Text_Red.svg"
                    placeholder="blurred"
                    loading="lazy"
                    height={90}
                    alt="Red Hat logo"
                    />
                </div>
                <div className='flex justify-center'>
                    <StaticImage
                    src="../assets/partnership-logos/SoulMachines_Logo_Color.png"
                    placeholder="blurred"
                    loading="lazy"
                    height={90}
                    alt="SoulMachines logo"
                    />
                </div> */}
                <div className='flex justify-center'>
                    <StaticImage
                    src="../assets/partnership-logos/AWS_EC2_Logo.png"
                    placeholder="blurred"
                    loading="lazy"
                    height={90}
                    alt="AWS EC2 logo"
                    />
                </div>
                <div className='flex justify-center'>
                    <StaticImage
                    src="../assets/partnership-logos/AWS_EKS_Logo.png"
                    placeholder="blurred"
                    loading="lazy"
                    height={90}
                    alt="AWS EKS logo"
                    />
                </div>
                <div className='flex justify-center'>
                    <StaticImage
                    src="../assets/partnership-logos/AWS_Logo.png"
                    placeholder="blurred"
                    loading="lazy"
                    height={90}
                    alt="AWS logo"
                    />
                </div>
                <div className='flex justify-center'>
                    <StaticImage
                    src="../assets/partnership-logos/Google_Cloud_Logo.png"
                    placeholder="blurred"
                    loading="lazy"
                    height={90}
                    alt="Google Cloud logo"
                    />
                </div>
                <div className='flex justify-center'>
                    <StaticImage
                    src="../assets/partnership-logos/Google_Kubernetes_Logo.png"
                    placeholder="blurred"
                    loading="lazy"
                    height={90}
                    alt="Google Kubernetes logo"
                    />
                </div>
                <div className='flex justify-center'>
                    <StaticImage
                    src="../assets/partnership-logos/Google_Logo.png"
                    placeholder="blurred"
                    loading="lazy"
                    height={90}
                    alt="Google logo"
                    />
                </div>
                <div className='flex justify-center'>
                    <StaticImage
                    src="../assets/partnership-logos/Google_Maps_Logo.png"
                    placeholder="blurred"
                    loading="lazy"
                    height={90}
                    alt="Google Maps logo"
                    />
                </div>
                <div className='flex justify-center'>
                    <StaticImage
                    src="../assets/partnership-logos/IBM_Cloud_Logo.png"
                    placeholder="blurred"
                    loading="lazy"
                    height={90}
                    alt="IBM Cloud logo"
                    />
                </div>
                <div className='flex justify-center'>
                    <StaticImage
                    src="../assets/partnership-logos/IBM_Logo.png"
                    placeholder="blurred"
                    loading="lazy"
                    height={90}
                    alt="IBM logo"
                    />
                </div>
                <div className='flex justify-center'>
                    <StaticImage
                    src="../assets/partnership-logos/IBM_Watson_AssistantDiscovery_Logo.png"
                    placeholder="blurred"
                    loading="lazy"
                    height={90}
                    alt="IBM Watson Assistant logo"
                    />
                </div>
                <div className='flex justify-center'>
                    <StaticImage
                    src="../assets/partnership-logos/Keycloak_Logo.png"
                    placeholder="blurred"
                    loading="lazy"
                    height={90}
                    alt="Keycloak logo"
                    />
                </div>
                <div className='flex justify-center'>
                    <StaticImage
                    src="../assets/partnership-logos/LDAP_Logo.png"
                    placeholder="blurred"
                    loading="lazy"
                    height={90}
                    alt="LDAP logo"
                    />
                </div>
                <div className='flex justify-center'>
                    <StaticImage
                    src="../assets/partnership-logos/Microsoft_AKS_Logo.png"
                    placeholder="blurred"
                    loading="lazy"
                    height={90}
                    alt="Microsoft AKS logo"
                    />
                </div>
                <div className='flex justify-center'>
                    <StaticImage
                    src="../assets/partnership-logos/Microsoft_Azure_AD_Logo.png"
                    placeholder="blurred"
                    loading="lazy"
                    height={90}
                    alt="Microsoft Azure AD logo"
                    />
                </div>
                <div className='flex justify-center'>
                    <StaticImage
                    src="../assets/partnership-logos/Microsoft_Azure_Logo.png"
                    placeholder="blurred"
                    loading="lazy"
                    height={90}
                    alt="Microsoft Azure logo"
                    />
                </div>
                <div className='flex justify-center'>
                    <StaticImage
                    src="../assets/partnership-logos/Microsoft_Graph_Logo.png"
                    placeholder="blurred"
                    loading="lazy"
                    height={90}
                    alt="Microsoft Graph logo"
                    />
                </div>
                <div className='flex justify-center'>
                    <StaticImage
                    src="../assets/partnership-logos/Microsoft_Logo.png"
                    placeholder="blurred"
                    loading="lazy"
                    height={90}
                    alt="Microsoft logo"
                    />
                </div>
                <div className='flex justify-center'>
                    <StaticImage
                    src="../assets/partnership-logos/Microsoft_Outlook_Calendar_Logo.png"
                    placeholder="blurred"
                    loading="lazy"
                    height={90}
                    alt="Microsoft Outlook Calendar logo"
                    />
                </div>
                <div className='flex justify-center'>
                    <StaticImage
                    src="../assets/partnership-logos/Microsoft_Teams_Logo.png"
                    placeholder="blurred"
                    loading="lazy"
                    height={90}
                    alt="Microsoft Teams logo"
                    />
                </div>
                <div className='flex justify-center'>
                    <StaticImage
                    src="../assets/partnership-logos/MongoDB_Atlas_Logo.png"
                    placeholder="blurred"
                    loading="lazy"
                    height={90}
                    alt="MongoDB Atlas logo"
                    />
                </div>
                <div className='flex justify-center'>
                    <StaticImage
                    src="../assets/partnership-logos/Pindrop_Logo.png"
                    placeholder="blurred"
                    loading="lazy"
                    height={90}
                    alt="Pindrop logo"
                    />
                </div>
                <div className='flex justify-center'>
                    <StaticImage
                    src="../assets/partnership-logos/Red_Hat_Logo.png"
                    placeholder="blurred"
                    loading="lazy"
                    height={90}
                    alt="Red Hat logo"
                    />
                </div>
                <div className='flex justify-center'>
                    <StaticImage
                    src="../assets/partnership-logos/RH_Openshift_Logo.png"
                    placeholder="blurred"
                    loading="lazy"
                    height={90}
                    alt="RH Openshift logo"
                    />
                </div>
                <div className='flex justify-center'>
                    <StaticImage
                    src="../assets/partnership-logos/Slack_Logo.png"
                    placeholder="blurred"
                    loading="lazy"
                    height={90}
                    alt="Slack logo"
                    />
                </div>
                <div className='flex justify-center'>
                    <StaticImage
                    src="../assets/partnership-logos/Soul_Machines_Logo.png"
                    placeholder="blurred"
                    loading="lazy"
                    height={90}
                    alt="Soul Machines logo"
                    />
                </div>
                    {/* {logos.map((index) => 
                        <div className="flex justify-center"> 
                            <StaticImage
                                src={`../assets/partnership-logos/${index.name}.png`}
                                placeholder="blurred"
                                loading="lazy"
                                height={90}
                                alt={index.name}
                            />
                        </div>
                    )} */}
            </Carousel>
        )
    }
};

export default LogoCarousel