import React, { useEffect, useRef, useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { LinkButton } from "../components/button";
import LogoCarousel from "../components/carousel";
import Layout from "../components/layout";
import Section from "../components/section";
import SEO from "../components/seo";
import ImageAccordionSection from "../components/sections/image-accordion";
import LandingPageVideoMP4 from "../assets/landing-page-video.mp4";
import LandingPageVideoWebM from "../assets/landing-page-video.webm";
import CTADemo from "../components/sections/cta-demo";
import clsx from "clsx";

const LandingPage = () => {
  const [isVideoLoaded, setVideoLoaded] = useState(false);
  const videoRef = useRef();

  const onVideoLoad = () => {
    setVideoLoaded(true);
  };

  const onVideoLoadError = () => {};

  const onVideoPause = () => {
    try {
      videoRef.current.play();
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (isVideoLoaded) {
      try {
        videoRef.current.play();
      } catch (err) {
        console.error(err);
      }
    }
  }, [isVideoLoaded]);

  return (
    <Layout>
      {/* Video Hero */}
      <section
        id="hero"
        className="relative bg-slate-900/50 after:bg-slate-900/60 after:block after:h-full after:absolute after:right-0 after:left-0 after:top-0 after:content-['']"
      >
        <div className="z-0 absolute left-0 bottom-0 right-0 top-0 overflow-hidden">
          <video
            onLoadedData={onVideoLoad}
            onPause={onVideoPause}
            ref={videoRef}
            preload="auto"
            autoPlay
            loop
            muted
            playsInline
            tabIndex="-1"
            className={clsx(
              "object-cover w-full h-full max-h-full aspect-video object-center"
            )}
          >
            <source src={LandingPageVideoWebM} type="video/webm" />
            <source
              src={LandingPageVideoMP4}
              type="video/mp4"
              onError={onVideoLoadError}
            />
          </video>
        </div>

        <div className="z-10 relative flex h-full items-center px-8 py-36 sm:py-48 md:py-64 xl:px-0 container max-w-7xl mx-auto">
          <div className="max-w-sm sm:max-w-md md:max-w-xl text-white">
            <div>
              <h1 className="text-5xl sm:text-6xl md:text-7xl font-bold">
                Elevated Customer Engagement
              </h1>
              <p className="mt-5 text-lg md:text-xl font-normal w-3/4">
                Create a personalized AI-powered experience for your customers
                with Coeus.
              </p>
            </div>
            <LinkButton className="mt-8" href="/pricing">
              Get started
            </LinkButton>
          </div>
        </div>
      </section>

      {/* Partnerships */}
      <section className="py-10">
        <h2 className="text-2xl text-center font-bold">In partnership with</h2>
        <div className="mt-10">
          <LogoCarousel />
        </div>
      </section>

      {/* Chat image section */}
      <Section className="bg-gray-50">
        <div className="space-y-10 xl:mx-auto xl:grid xl:grid-cols-2 xl:items-center xl:gap-24">
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 content-center">
            <StaticImage
              src="../assets/webchat-contact-form.png"
              className="z-10 mx-auto mb-0 sm:mb-20 shadow-lg overflow-hidden rounded-md"
              placeholder="blurred"
              layout="fixed"
              quality={100}
              width={290}
              loading="lazy"
              alt=""
            />
            <StaticImage
              src="../assets/webchat-airport.png"
              className="mt-0 sm:mt-20 mx-auto z-0 overflow-hidden shadow-md rounded-md"
              placeholder="blurred"
              layout="fixed"
              quality={100}
              width={290}
              loading="lazy"
              alt=""
            />
          </div>
          <div>
            <h1 className="text-3xl md:text-4xl font-bold">What is Coeus?</h1>
            <p className="mt-2 text-base md:text-lg font-light">
              Coeus is an AI-enhaced virtual assistant that handles routine to
              complex inquiries and requests for your customers, business
              partners, employees, and call center agents. Coeus provides a rich
              customer experience while reducing operational costs and expanding
              safe, virtual interaction with your business.
            </p>
            <LinkButton className="mt-8" href="/pricing">
              Get started
            </LinkButton>
          </div>
        </div>
      </Section>

      {/* The problem section */}
      <Section className="">
        <div className="space-y-10 lg:mx-auto lg:grid lg:grid-cols-2 lg:items-center lg:gap-24">
          <div className="">
            <h1 className="text-3xl md:text-4xl font-bold">The Problem</h1>
            <p className="mt-2 text-base md:text-lg font-light">
              Today’s customers expect your customer service to be fast, always
              available, and accessible via multiple devices. Customer Contact
              Centers face the need to answer more calls with fewer agents. To
              meet these demands, you need to:
            </p>
            <ol className="pl-5 list-disc mt-2 text-base md:text-lg font-light">
              <li>
                Allow self-service for customers and employees to access
                information
              </li>
              <li>Provide access across any media platform</li>
              <li>
                Equip customer service agents with tools to quickly find answers
                to more in-depth questions
              </li>
            </ol>
            <LinkButton href="/solutions/" className="mt-8">
              The Solution
            </LinkButton>
          </div>
          <div className="flex justify-center lg:justify-start mb-8">
            <StaticImage
              src="../assets/Problem_img_filler.png"
              className="shadow-md rounded-xl overflow-hidden"
              imgClassName="object-left-bottom rounded-xl"
              width={500}
              placeholder="blurred"
              loading="lazy"
              layout="fixed"
              alt=""
            />
          </div>
        </div>
      </Section>

      {/* Key benefits section */}
      <Section className="bg-gray-50">
        <div className="space-y-2 mx-auto max-w-2xl lg:max-w-4xl">
          <h2 className="text-3xl md:text-4xl font-bold text-center">
            Key Benefits
          </h2>
          <p className="text-center font-light text-base md:text-lg mx-auto">
            Coeus integrates with your business systems and knowledge
            repositories to provide in-depth and consistent responses using
            natural language, allowing interactions based on your users’
            preferences and devices. Coeus provides a rich customer experience
            while reducing operational costs and expanding safe, virtual
            interaction with your business.
          </p>
        </div>
        <div className="space-y-8 mt-12 lg:space-y-0 lg:grid lg:grid-cols-3 lg:content-center lg:gap-4">
          <div className="space-y-2">
            <div className="flex justify-center">
              <h1 className="text-2xl font-bold">Calls Handled by Agents</h1>
            </div>
            <div className="flex justify-center">
              <h1 className="text-coeusRed text-6xl font-bold">-50%</h1>
            </div>
          </div>

          <div className="space-y-2">
            <div className="flex justify-center">
              <h1 className="text-2xl font-bold">Time to be Connected</h1>
            </div>
            <div className="flex justify-center">
              <h1 className="text-coeusRed text-6xl font-bold">5s</h1>
            </div>
          </div>

          <div className="space-y-2">
            <div className="flex justify-center">
              <h1 className="text-2xl font-bold">Customer Satisfaction</h1>
            </div>
            <div className="flex justify-center">
              <h1 className="text-coeusRed text-6xl font-bold">99%</h1>
            </div>
          </div>
        </div>
      </Section>

      {/* Industries section */}
      <ImageAccordionSection
        tabs={[
          {
            name: "Business",
            description:
              "Whether it be consulting, financing, or a specific solution, DFS has what your business needs to succeed.",
            Image: (
              <StaticImage
                src="../assets/business_alt.png"
                imgClassName="rounded-xl"
                placeholder="blurred"
                loading="lazy"
                alt=""
              />
            ),
          },
          {
            name: "Healthcare",
            description:
              "Healthcare organizations are facing the need to quickly modernize access while securing PHI and meeting regulations. DFS is here to help you move forward quickly and cost efficiently.",
            Image: (
              <StaticImage
                src="../assets/healthcare_alt.png"
                imgClassName="rounded-xl"
                placeholder="blurred"
                loading="lazy"
                alt=""
              />
            ),
          },
          {
            name: "Federal Government",
            description:
              "We work directly with government agencies to incorporate market-ready end-to-end solutions that benefit everyday US citizens.",
            Image: (
              <StaticImage
                src="../assets/Federal_Gov.jpeg"
                imgClassName="rounded-xl"
                placeholder="blurred"
                loading="lazy"
                alt=""
              />
            ),
          },
          {
            name: "State and Local Government",
            description:
              "DFS works directly with local and state governments to introduce high-quality solutions that benefit their citizens.",
            Image: (
              <StaticImage
                src="../assets/State_Gov.jpeg"
                imgClassName="rounded-xl"
                placeholder="blurred"
                loading="lazy"
                alt=""
              />
            ),
          },
        ]}
        title="Industries Where Coeus Can Be Integrated"
        description="These Industries Trust DFS"
      />

      <CTADemo />
    </Layout>
  );
};

export default LandingPage;

export const Head = () => {
  return (
    <SEO
      title="Customer Service AI Platform"
      description="Coeus is a customer service platform that utilizes Conversational AI to provide a unique, high-quality customer experience."
    ></SEO>
  );
};
